<template>
    <div v-if="props.query" ref="container">
        <slot v-bind="slotProps"></slot>
    </div>
    <slot v-else v-bind="slotProps"></slot>
</template>

<script setup lang="ts">
    import {
        ref,
        defineProps,
        inject,
        onMounted,
        onBeforeUnmount,
        computed,
        defineEmits,
        watch,
        withDefaults,
    } from "vue";
    import type { IContainerSizeQueryResults, IQuery } from 'o365.vue.components.MediaQueryProvider.vue';
    import { containerSizeQueryProviderKey } from 'o365.modules.vue.injectionKeys.js';

    export interface IMatchContainerSizeQueryProps {
        query?: IQuery;
        fallback?: boolean;
        resizeObserverBox?: ResizeObserverBoxOptions;
    }

    export interface IMatchContainerSizeQueryDefaultProps {
        fallback: boolean;
        resizeObserverBox: ResizeObserverBoxOptions;
    }

    const props = withDefaults<IMatchContainerSizeQueryProps, IMatchContainerSizeQueryDefaultProps>(defineProps<IMatchContainerSizeQueryProps>(), {
        fallback: false,
        resizeObserverBox: 'content-box'
    });

    const resizeObserver = new ResizeObserver(onResize);
    const matches = ref<boolean>(props.fallback);
    const container = ref(null);

    const containerSizeQueryResults = inject<IContainerSizeQueryResults | null>(containerSizeQueryProviderKey);

    const emit = defineEmits<{ (e: 'change', newValue: boolean, oldValue: boolean): void }>();

    function initializeContainerResizeObserver(): void {
        if (!container.value) {
            return;
        }

        resizeObserver.observe(container.value, {
            box: props.resizeObserverBox
        });
    }

    function terminateResizeObserver(): void {
        resizeObserver.disconnect();
    }

    function onResize(entries: Array<ResizeObserverEntry>, observer: ResizeObserver): void {
        const query = props.query;

        if (!query) {
            return;
        }

        if (entries.length !== 1) {
            console.error('Failed to check container queries as multiple elements was returned')
            return;
        }

        const resizeObserverEntry = entries[0];

        let resizeObserverEntryBox: Readonly<Array<ResizeObserverSize>>;

        switch (query.boxType ?? 'content-box') {
            case 'border-box':
                resizeObserverEntryBox = resizeObserverEntry.borderBoxSize;
                break;
            case 'content-box':
                resizeObserverEntryBox = resizeObserverEntry.contentBoxSize;
                break;
            case 'device-pixel-content-box':
                resizeObserverEntryBox = resizeObserverEntry.devicePixelContentBoxSize;
                break;
        }

        const height = resizeObserverEntryBox[0].blockSize;
        const width = resizeObserverEntryBox[0].inlineSize;

        const heightMatch = (query.minHeight ?? 0) < height && height < (query.maxHeight ?? Number.MAX_VALUE);
        const widthMatch = (query.minWidth ?? 0) < width && width < (query.maxWidth ?? Number.MAX_VALUE);

        const queryMatch = heightMatch && widthMatch;

        if (matches.value === queryMatch) {
            return;
        }

        emit('change', queryMatch, matches.value);

        matches.value = queryMatch;
    }

    onMounted(() => {
        terminateResizeObserver();
        initializeContainerResizeObserver();
    });

    onBeforeUnmount(() => terminateResizeObserver());

    watch(
        () => props.query,
        (newQuery, oldQuery) => {
            if (!newQuery || !oldQuery) {
                terminateResizeObserver();
            }

            if (newQuery && !oldQuery) {
                initializeContainerResizeObserver();
            }
        }
    );

    const slotProps = computed(() => {
        if (props.query) {
            return {
                matches: matches,
            };
        }

        if (!containerSizeQueryResults) {
            console.error(
                `[vue-component]: <MatchContainerSizeQuery /> should be a descendant of a <ContainerSizeQueryProvider /> component tree or have a 'query' prop.`
            );
        }

        return containerSizeQueryResults;
    });
</script>
